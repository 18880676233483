import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpInterceptorModule } from './http-interceptor.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './translater-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage'
import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NgPipesModule } from 'ngx-pipes';
import { ActionSheet } from '@awesome-cordova-plugins/action-sheet/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileBlobPipe } from './core/pipes/blob.pipe';
import { LazyLoadImageModule} from 'ng-lazyload-image'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpInterceptorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot({
      driverOrder: [cordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),
    QRCodeModule,
    NgPipesModule,
    LazyLoadImageModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, BarcodeScanner,ActionSheet,OneSignal,
    BLE,FileBlobPipe
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
