import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileBlob'
})
export class FileBlobPipe implements PipeTransform {

  async transform(value: any, ...args: unknown[]){


    

    value = value.replace("url(","")
    value = value.replace(")","")

    const reader = new FileReader();
    reader.readAsDataURL(value);
    console.log(reader.readAsDataURL(value))
    reader.onload = () => {
        console.log(reader.result);
    };


    var blob = new Blob(value,{type:'image/jpg'})
    console.log(blob)
    return blob;


  //   var res = await fetch(value);
  // var blob = await res.blob();

  // return new Promise((resolve, reject) => {
  //   var reader  = new FileReader();
  //   reader.addEventListener("load", function () {
  //       resolve(reader.result);
  //   }, false);

  //   reader.onerror = () => {
  //     return reject(this);
  //   };
  //   console.log(reader.readAsDataURL(blob))
  //   return reader.readAsDataURL(blob);

  // })

  
  }

}
