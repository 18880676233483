// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://parantez.kutuphanedeyim.net/',
   serverUrl: 'https://parantez.kutuphanedeyim.net/',
  appUrl: 'https://kutuphanedeyim.net/',
  appImageUrl: 'http://bc.vakifglobal.com/assets/img/nearme.png',
  oneSignal: {
    appId: '898b1848-91ea-4b87-8d11-13c41e4182af',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
