import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/security/auth.guard';

const routes: Routes = [
  {
    path: 'tablet',
    loadChildren: () => import('./tablet/tablet.module').then( m => m.TabletPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'biblio-detail/:id',
    loadChildren: () => import('./pages/biblio-detail/biblio-detail.module').then( m => m.BiblioDetailPageModule)
  },
  {
    path: 'profile',
    canLoad:[AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'manage-profile',
    loadChildren: () => import('./pages/manage-profile/manage-profile.module').then(m => m.ManageProfilePageModule)
  },
  {
    path: 'branche',
    loadChildren: () => import('./pages/branche/branche.module').then( m => m.BranchePageModule)
  },
  {
    path: 'check-in-out',
    loadChildren: () => import('./pages/check-in-out/check-in-out.module').then( m => m.CheckInOutPageModule)
  },
  {
    path: 'navigation',
    loadChildren: () => import('./pages/navigation/navigation.module').then(m => m.NavigationPageModule)
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
