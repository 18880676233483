import { Component, NgZone, OnInit } from '@angular/core';

import { TranslationService } from './core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

// import OneSignal from 'onesignal-cordova-plugin';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';


import { BaseComponent } from './base.component';
import { UserAuth } from './core/domain-classes/user-auth';
import { SecurityService } from './core/security/security.service';
import Utils from './utils';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CachingService } from './core/services/caching.service';
import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  showRedirect:boolean=false;
  redirectUrl:string = "";
  constructor(
    private securityService: SecurityService,
    public translate: TranslateService,
    private translationService: TranslationService,
    private ngZone:NgZone,
    private router:Router,
    private cachingService:CachingService,
    platform:Platform,
    private deviceService: DeviceDetectorService
  ) 
  {
    super()
    this.initializeApp();
    this.cachingService.initStorage();
   
  }

  ngOnInit(): void {
      this.getAuthObj()
  }

  async initializeApp(){
    if(!this.deviceService.isDesktop()){
      
      if(this.deviceService.device =="iPhone"){
        this.redirectUrl ="https://apps.apple.com/tr/app/kütüphanedeyim/id1493346382?l=tr"
        this.showRedirect = true;
      }
      if(this.deviceService.device =="Android"){
        this.redirectUrl ="https://play.google.com/store/apps/details?id=io.parantez.kutuphanedeyim&hl=tr&pli=1"
        this.showRedirect = true;
      }
    }

    if(!this.deviceService.isMobile()){
    }

    this.translate.addLangs(['tr','en']);
    this.translate.setDefaultLang('tr');
    this.setLanguage();

    if (Capacitor.isNativePlatform()) {
      this.setupOneSignal();
      await Utils.sleep(2000);//Sleep özelliğini dene
      SplashScreen.hide();
    } else {
    }

  }

  setupOneSignal() {

    const appId = environment?.oneSignal?.appId;

    
    if (appId) {

      OneSignal.setAppId(appId);
      OneSignal.setNotificationOpenedHandler((res: OpenedEvent) => {
        console.log('[ONE_SIGNAL] push opened', res);

        // const notificationData: any = res.notification.additionalData;

        // let page = null;
        // let queryParams = {};

       
        // if (page) {
        //   this.ngZone.run(() => {
        //     this.router.navigate([page], { queryParams });
        //   });
        // }

      });

      OneSignal.setNotificationWillShowInForegroundHandler((event: NotificationReceivedEvent) => {
        const notification = (event as any).notification
        console.log('[ONE_SIGNAL] push received', notification);
      })

    }
  }


  setLanguage() {
    const currentLang = this.translationService.getSelectedLanguage();
    if (currentLang) {
      this.sub$.sink = this.translationService.setLanguage(currentLang)
      .subscribe(() => { });
    }
    else {
      const browserLang = this.translate.getBrowserLang();
      const lang = browserLang.match(/tr|en/) ? browserLang : 'tr';
      this.sub$.sink = this.translationService.setLanguage(lang).subscribe(() => { });
    }
  }

  getAuthObj() {
    this.sub$.sink = this.securityService.securityObject$
      .subscribe((c: UserAuth) => {
        if (c) {
          //KULLANICI LOGİN İSE
          // const online: OnlineUser = {
          //   email: c.email,
          //   id: c.id,
          //   connectionId: this.signalrService.connectionId,
          //   branchCode:c.branchCode
          // };
          // this.signalrService.addUser(online);
        }
      });
  }
}
